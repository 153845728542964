.DrawerToggle {
  display: none;
  flex-flow: column;
  padding: 1rem 0;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  width: 40px;
  height: 100%;
  cursor: pointer;
}

.DrawerToggle div {
  width: 90%;
  height: 0.3rem;
  background-color: rgb(214, 214, 214);
  border: 1px solid black;
}

@media (max-width: 855px) {
  .DrawerToggle {
    display: flex;
  }
}
