.SpinnerWrapper {
  background: #131313;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.TextHeader {
  font-size: 3rem;
  margin: 0 1rem;
  color: white;
  text-shadow: 4px 4px 4px black;
  text-align: center;
}

@media (max-width: 855px) {
  .TextHeader {
    font-size: 1.5rem;
  }
}
