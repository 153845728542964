.Layout {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: black;
}

.Layout::-webkit-scrollbar {
  display: none;
}
