.RecruitmentCarousel > div > div > div > ul li {
  background: transparent !important;
  font-size: 0.8rem;
}

.RecruitmentCarousel > div > div > ul {
  bottom: -10px !important;
}

.RecruitmentCarousel .TextDiv:active,
.RecruitmentCarousel .TextDiv:focus {
  cursor: grabbing;
}

.RecruitmentCarousel .TextDiv {
  text-align: justify;
  cursor: grab;
  padding: 25px 60px 40px 60px;
}

.RecruitmentCarousel .Requirements {
  list-style-type: "\2714";
  list-style-position: outside;
  padding-left: 1.2rem;
}

.RecruitmentCarousel a {
  color: inherit;
  text-decoration: none;
}

.TextLinkWrapper {
  margin-top: 1rem;
  text-align: center;
}

.RecruitmentCarousel .Requirements li {
  line-height: 1.5rem;
}

.RecruitmentCarousel .Requirements li span {
  display: block;
  margin-left: 0.7rem;
}

.RecruitmentCarousel .TextDiv h3 {
  font-size: 1rem;
}

.RecruitmentCarousel .TextDiv .ListHeader {
  margin-bottom: 6px;
  text-align: left;
}

@media (max-width: 855px) {
  .RecruitmentCarousel .TextDiv {
    text-align: center;
  }

  .RecruitmentCarousel .Requirements {
    padding-left: 15%;
    text-align: left;
  }

  .RecruitmentCarousel .TextDiv {
    padding: 25px 25px 40px 25px;
  }
}
