.TeamCarousel > div > div > div > ul li {
  background: transparent !important;
  font-size: 0.8rem;
}

.TeamCarousel > div > div > ul {
  bottom: -10px !important;
}

.TeamCarousel .TextDiv:active,
.TeamCarousel .TextDiv:focus {
  cursor: grabbing;
}

.TeamCarousel .TextDiv {
  text-align: justify;
  cursor: grab;
  padding: 25px 60px 40px 60px;
  text-align: center;
}

.TeamCarousel .Requirements {
  list-style-type: initial;
  list-style-position: outside;
  padding-left: 0;
  list-style-type: none;
  margin-bottom: 8px;
}

.TeamCarousel a {
  color: inherit;
  text-decoration: none;
}

.TextLinkWrapper {
  margin-top: 1rem;
  text-align: center;
}

.TeamCarousel .Requirements li {
  line-height: 1.5rem;
  text-align: center;
}

.TeamCarousel .Requirements li span {
  display: block;
}

.TeamCarousel .TextDiv h3 {
  text-align: center;
  font-size: 1rem;
}

.TeamCarousel .TextDiv .ListHeader {
  margin-bottom: 6px;
}

@media (max-width: 855px) {
  .TeamCarousel .TextDiv {
    padding: 25px 25px 40px 25px;
  }
}
