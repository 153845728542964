.FlagButton {
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  margin: 0 0.3rem;
}

.FlagButton:hover {
  cursor: pointer;
}

.FlagButton img {
  height: 1.2rem;
}
