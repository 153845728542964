.SideDrawer {
  display: none;
  position: fixed;
  width: 280px;
  max-width: 60vw;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: transparent;
  backdrop-filter: blur(5px);
  padding: 8rem 1rem 1rem 2rem;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

@media (max-width: 855px) {
  .SideDrawer {
    display: block;
  }
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}

.Logo {
  height: 60px;
  margin: 0.7rem auto;
  text-align: center;
}

.Logo img {
  height: 100%;
}
