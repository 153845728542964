.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  color: #c4c4c4;
}

.IncreasedVisibilty,
.IncreasedVisibilty a {
  font-weight: 700;
  color: white;
  text-shadow: 1px 1px black;
}

@media (max-width: 855px) {
  .NavigationItems {
    flex-flow: column;
    height: 100%;
  }

  .NavigationItems span {
    display: none;
  }
}
