@import "react-big-calendar/lib/sass/styles";

$mobile-size: 855px;

.rbc-calendar {
  width: 80%;
  margin: auto;
  font-family: inherit;
  font-weight: 100;
}

.rbc-month-row {
  min-height: 5rem;

  .rbc-today {
    background-color: rgba(0, 0, 0, 0.445);
  }

  .rbc-off-range-bg {
    background-color: rgba(0, 0, 0, 0.37);
  }
}

.rbc-toolbar {
  .rbc-btn-group {
    margin: 0.5rem auto;

    &:last-child button:nth-child(2),
    &:last-child button:nth-child(3) {
      display: none;
    }

    & button {
      color: lightskyblue;

      &.rbc-active,
      &:active,
      &:focus {
        color: lightskyblue;
        background-color: rgba(0, 0, 0, 0.445);
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.589);
      }
    }
  }
}

@media (max-width: $mobile-size) {
  .rbc-calendar {
    width: 90%;
    margin: auto;
    font-family: inherit;
    font-size: 0.8rem;
  }

  .rbc-toolbar {
    font-size: unset;

    button {
      font-size: 0.6rem;
    }
  }

  .rbc-month-row {
    min-height: 3rem;
  }

  .rbc-event-content {
    font-size: 0.4rem;
  }
}
