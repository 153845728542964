.Footer {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  z-index: 100;
  padding: 0 1%;
  margin-bottom: 0.1%;
}

.SocialMedia {
  display: flex;
  flex-flow: row;
  min-width: 5%;
  justify-content: center;
  margin-right: 15px;
}

.SocialButton {
  height: 20px;
  width: 20px;
  margin: auto 6px;
  filter: saturate(70%);
}

.SocialButton:hover {
  filter: saturate(100%);
}

.FBLogo {
  background: url("../../assets/fb-icon.png") center/cover, transparent;
}

.TTLogo {
  background: url("../../assets/tt-icon.png") center/cover, transparent;
}

.IGLogo {
  background: url("../../assets/ig-icon.png") center/cover, transparent;
}

.DiscordLogo {
  background: url("../../assets/discord-icon.png") center/cover, transparent;
}

.TwitchLogo {
  background: url("../../assets/twitch-icon.png") center/cover, transparent;
}

.YTLogo {
  background: url("../../assets/yt-icon.png") center/cover, transparent;
}

.TikTokLogo {
  background: url("../../assets/tiktok-icon.png") center/cover, transparent;
}

.FooterLeftSide,
.FooterLeftSide a {
  display: flex;
  justify-content: space-evenly;
  color: rgba(99, 99, 99, 0.521);
  margin: auto 5px;
  font-size: 0.8rem;
}

.FooterLeftSide div,
.FooterLeftSide a {
  margin: 0 5px;
}

.FooterLeftSide a {
  text-decoration: none;
}

.FooterLeftSide a:hover {
  text-decoration: underline;
}

@media (max-width: 850px) {
  .FooterLeftSide,
  .FooterLeftSide a {
    font-size: 0.5rem;
  }
}

@media (max-width: 330px) {
  .FooterLeftSide {
    flex-flow: column;
  }
}
