.Team {
  background: #131313;
  height: 100vh;
  text-align: center;
  align-items: center;
  overflow: hidden;
  z-index: 50;
  user-select: none;
}

.Team ul {
  list-style-type: none;
}

.Scene,
.Layer {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.TeamBackground {
  background-image: url("../../assets/team-background.jpg");
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 110vw;
  height: 110vh;
  left: -5%;
  top: -5%;
}

.TeamLayer2 {
  background-image: url("../../assets/team-layer-2.png");
}

.TeamLayer1 {
  background-image: url("../../assets/team-layer-1.png");
  width: 120vw;
  height: 120vh;
  left: -12vw;
  top: auto;
  bottom: -12vh;
}

.TeamLayer0 {
  background-image: url("../../assets/team-layer-0.png");
  filter: brightness(0.9);
  width: 95vw;
  height: 95vh;
  bottom: -10vh;
  left: -20vw;
  top: auto;
}

.ContentWrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  text-align: center;
  box-shadow: inset 0 30px 15px -20px black, inset 0 -30px 15px -20px black;
}

.MainTextBox {
  position: absolute;
  display: flex;
  flex-flow: column;
  width: 40vw;
  max-height: 70vh;
  top: 20vh;
  left: 47vw;
  padding: 1rem 0.5rem;
  background: rgba(22, 22, 22, 0.8);
  border-radius: 20px;
  box-shadow: 0px 0px 20px 10px #131313;
  backdrop-filter: blur(1px);

  color: white;
  overflow: scroll;
  user-select: none;
}

.MainTextBox h1 {
  margin-top: 0.3rem;
  margin-bottom: 0;
}

@media (min-width: 856px) {
  .MainTextBox {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .MainTextBox::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 855px) {
  .TeamBackground {
    background-position-x: 35%;
  }

  .TeamLayer0 {
    width: 150vw;
    background-position-x: 50%;
  }

  .MainTextBox {
    width: 80vw;
    top: 15vh;
    left: 8vw;
  }

  .MainTextBox h1 {
    font-size: 1.75rem;
  }
}
