.NavigationItem {
  display: flex;
  font-size: 0.9rem;
  margin: 0 1em;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
}

.NavigationItem a {
  line-height: 2.15;
  position: relative;
  text-align: center;
  color: #c4c4c4;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-decoration: none;
}

.NavigationItem a:hover {
  color: lightskyblue;
}

.NavigationItem:active a,
.NavigationItem.active a {
  color: rgb(29, 29, 85);
}

.NavigationItem::before,
.NavigationItem::after,
.NavigationItem a::before,
.NavigationItem a::after {
  content: "";
  position: absolute;
  background: rgb(29, 29, 85);
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);
  transition-timing-function: cubic-bezier(0.44, 0.05, 0.55, 0.95);
}

/* .NavigationItem::before,
.NavigationItem::after {
  top: 0;
  width: 4px;
  height: 100%;
  -webkit-transform: scale3d(10, 0, 1);
  transform: scale3d(0.1, 0, 1);
}

left line
.NavigationItem::before {
  left: 0;
}

right line
.NavigationItem::after {
  right: 0;
} */

.NavigationItem a::before,
.NavigationItem a::after {
  left: 0;
  width: 100%;
  height: 4px;
  -webkit-transform: scale3d(0, 0.1, 1);
  transform: scale3d(0, 0.1, 1);
}

/* top line */
.NavigationItem a::before {
  top: 0;
}

/* bottom line */
.NavigationItem a::after {
  bottom: 0;
}

.NavigationItem a.active::before,
.NavigationItem a.active::after,
.NavigationItem:active::before,
.NavigationItem:active::after {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

@media (max-width: 855px) {
  .NavigationItem {
    display: block;
    box-sizing: border-box;
    margin: 0.7rem;
    width: 100%;
  }

  .NavigationItem a.active::before,
  .NavigationItem a.active::after,
  .NavigationItem:active::before,
  .NavigationItem:active::after {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
}
