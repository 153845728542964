.Toolbar {
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  padding: 0 1vw;
  margin-top: 0.5vh;
  user-select: none;
}

.LogoLink {
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 700;
}

.LogoTextContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  color: #c4c4c4;
}

.Logo {
  height: 60px;
  margin: auto;
  align-items: center;
}

.Logo img {
  height: 100%;
}

.LogoTextContainer {
  display: flex;
  flex-flow: column;
  padding-left: 1rem;
  justify-content: center;
  height: 100%;
  font-size: 1rem;
  letter-spacing: 0.2rem;
}

.IncreasedVisibilty {
  color: white;
  text-shadow: 1px 1px black;
}

.glitch {
  height: 1.2rem;
}

.LogoLink:hover {
  @mixin glitchCopy {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch {
    animation: glitch-skew 5s infinite linear alternate-reverse;

    &::before {
      @include glitchCopy;
      left: 2px;
      text-shadow: -2px 0 #ff00c1;
      clip: rect(44px, 450px, 56px, 0);
      animation: glitch-anim 5s infinite linear alternate-reverse;
    }

    &::after {
      @include glitchCopy;
      left: -2px;
      text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
      animation: glitch-anim2 1s infinite linear alternate-reverse;
    }
  }

  @keyframes glitch-anim {
    $steps: 20;
    @for $i from 0 through $steps {
      #{percentage($i*(1/$steps))} {
        clip: rect(random(100) + px, 9999px, random(100) + px, 0);
        transform: skew((random(100) / 100) + deg);
      }
    }
  }

  @keyframes glitch-anim2 {
    $steps: 20;
    @for $i from 0 through $steps {
      #{percentage($i*(1/$steps))} {
        clip: rect(random(100) + px, 9999px, random(100) + px, 0);
        transform: skew((random(100) / 100) + deg);
      }
    }
  }

  @keyframes glitch-skew {
    $steps: 10;
    @for $i from 0 through $steps {
      #{percentage($i*(1/$steps))} {
        transform: skew((random(10) - 5) + deg);
      }
    }
  }
}

@media (max-width: 855px) {
  .DesktopOnly {
    display: none;
  }

  .Toolbar {
    padding: 0 5vw;
  }

  .LogoTextContainer {
    display: none;
  }
}
