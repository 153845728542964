.MainPage {
  background: #131313;
  height: 100vh;
  text-align: center;
  align-items: center;
  overflow: hidden;
  z-index: 50;
  user-select: none;
}

.MainPage ul {
  list-style-type: none;
}

.Scene,
.Layer {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.MainBackground {
  background-image: url("../../assets/main-background.jpg");
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 110%;
  height: 110%;
  left: -5%;
  top: -5%;
}

.MainLayer2 {
  background-image: url("../../assets/main-layer-2.png");
}

.MainLayer1 {
  background-image: url("../../assets/main-layer-1.png");
}

.MainLayer0 {
  background-image: url("../../assets/main-layer-0.png");
  filter: saturate(0.8) brightness(0.9);
  width: 105vw;
  height: 105vh;
  bottom: -5vh;
  left: -25vw;
  top: auto;
}

.ContentWrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  text-align: center;
  box-shadow: inset 0 30px 15px -20px black, inset 0 -30px 15px -20px black;
}

.MainTextBox {
  position: absolute;
  display: flex;
  flex-flow: column;
  height: 15rem;
  top: 35vh;
  left: 50vw;
  padding: 0.5rem;
  color: #f0f0f0;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.MainTextBox h1 {
  font-size: 3rem;
  margin: 0 auto;
  text-shadow: 5px 5px 5px black;
}

.MainTextBox ul {
  text-align: left;
  font-size: 1.6rem;
}

.MainTextBox ul li {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.MainTextBox ul li span {
  margin-left: 0.5rem;
  text-shadow: 2.5px 2.5px 1px black;
}

.MainTextBox svg {
  -webkit-filter: drop-shadow(2.5px 2.5px 1px black);
  filter: drop-shadow(2.5px 2.5px 1px black);
}

@media (max-width: 855px) {
  .MainLayer0 {
    width: 150vw;
    background-position-x: 50%;
    background-size: 1600px;
    background-repeat: no-repeat;
    bottom: -15vh;
  }

  .MainTextBox {
    top: 13vh;
    left: 0vw;
    right: 0vw;
    align-items: center;
  }

  .MainTextBox h1 {
    font-size: 2rem;
    margin-top: 3vh;
    margin-bottom: 42vh;
  }

  .MainTextBox ul {
    padding: 0;
    font-size: 0.6rem;
    display: flex;
    justify-items: center;
  }

  .MainTextBox ul li {
    margin-bottom: 1rem;
    width: 30vw;
    text-align: center;
  }

  .MainTextBox ul li span {
    margin: auto;
  }

  .MainTextBox svg {
    display: none;
  }
}
