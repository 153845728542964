.Credits {
  background: #131313;
  height: 100vh;
  text-align: center;
  align-items: center;
  overflow: hidden;
  z-index: 50;
  user-select: none;
}

.Credits ul {
  list-style-type: none;
}

.Scene,
.Layer {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.ContentWrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  text-align: center;
  box-shadow: inset 0 30px 15px -20px black, inset 0 -30px 15px -20px black;
}

.MainTextBox {
  position: absolute;
  text-align: center;
  width: 60vw;
  max-height: 70vh;
  top: 20vh;
  left: 20vw;
  padding: 1rem 0.5rem;
  background: rgba(22, 22, 22, 0.8);
  border-radius: 20px;
  box-shadow: 0px 0px 20px 10px #131313;
  backdrop-filter: blur(1px);
  color: white;
  overflow: scroll;
  user-select: none;
}

.MainTextBox h1 {
  margin-top: 0.3rem;
  margin-bottom: 0;
}

.MainTextBox ul {
  text-align: left;
  padding: 0 15%;
}

.MainTextBox ul li {
  margin-bottom: 0.5rem;
}

.MainTextBox a,
.MainTextBox a:hover {
  color: lightskyblue;
  text-decoration: none;
}

.CreditsBackground {
  background-image: url("../../assets/credits-background.jpg");
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 110vw;
  height: 110vh;
  left: -5%;
  top: -5%;
}

@media (min-width: 856px) {
  .MainTextBox {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .MainTextBox::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 855px) {
  .CreditsBackground {
    background-position-x: 35%;
  }

  .MainTextBox {
    width: 80vw;
    top: 15vh;
    left: 10vw;
  }

  .MainTextBox h1 {
    font-size: 1.75rem;
  }

  .MainTextBox ul {
    font-size: 0.7rem;
  }
}
